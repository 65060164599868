import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { View, LayoutAnimation } from "react-native";
import { makeStyles } from "@rneui/themed";

import { debounce } from "lodash";

import UserType from "common/types/UserType";
import MemberType from "common/types/MemberType";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import AutocompleteInput from "../../components/ui/AutocompleteInput";
import DoctorInfo from "../../components/ui/DoctorInfo";
import useKeyboard from "../../hooks/useKeyboard";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { trimText } from "common/helpers/helpers";

const RenderListItem = ({
  item,
  multipleSelection = false,
  selectedDoctors,
  setSelectedDoctors
}: {
  item: MemberType;
  multipleSelection?: boolean;
  selectedDoctors: MemberType[];
  setSelectedDoctors: (doctors: MemberType[]) => void;
}) => {
  const selectedItemIndex = useMemo(() => {
    return selectedDoctors.indexOf(item);
  }, [selectedDoctors, item]);

  const checked = selectedItemIndex !== -1;

  const onPressHandler = () => {
    if (multipleSelection) {
      if (checked) {
        selectedDoctors.splice(selectedItemIndex, 1);
      } else {
        selectedDoctors.push(item);
      }
      setSelectedDoctors([...selectedDoctors]);
    } else setSelectedDoctors([item]);
  };

  return (
    <DoctorInfo
      editable
      checked={checked}
      doctor={item}
      onPress={onPressHandler}
    />
  );
};

const DoctorSharingScreen = ({ navigation }) => {
  const styles = useStyles();

  const isKeyboardVisible = useKeyboard();

  const [text, setText] = useState<string>("");
  const [debouncedText, setDebouncedText] = useState<string>("");
  const [selectedDoctors, setSelectedDoctors] = useState<MemberType[]>([]);

  const trimAndSetDebouncedText = (text: string) => {
    const trimmedText = trimText(text);
    setDebouncedText(trimmedText);
  };
  const debounceHandler = useCallback(
    debounce(trimAndSetDebouncedText, 300),
    []
  );

  const onChangeTextHandler = (text) => {
    setText(text);
    debounceHandler(text);
  };

  /*const {data} = useGetUserWithQueryParamsQuery(
    {
      search: debouncedText,
      sort: 'lastName,ASC,firstName,ASC',
    },
    {skip: debouncedText.length === 0, refetchOnMountOrArgChange: true},
  );*/
  const data = useMemo(() => [], []);

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [data, text]);

  return (
    <ScreenContainer innerStyle={styles.container}>
      <View style={styles.flex1}>
        <Text h4 style={styles.textDarkGreyBlue}>
          Share your data with all your doctors to get the best care:
        </Text>

        <AutocompleteInput<UserType>
          label={LocalizedStrings.screens.doctorSharing.doctorsName.toUpperCase()}
          rightIcon={{ type: "feather", name: "search" }}
          value={text}
          onChangeText={onChangeTextHandler}
          flatListProps={{
            keyExtractor: (item) => item.user.user_id
          }}
          filterData={data}
          renderListItem={({ item }) => (
            <RenderListItem
              item={item}
              selectedDoctors={selectedDoctors}
              setSelectedDoctors={setSelectedDoctors}
            />
          )}
        />
        {!isKeyboardVisible && (
          <View>
            <Text capsSmall style={styles.textDarkGreyBlue}>
              {LocalizedStrings.screens.doctorSharing.sharedWith}
            </Text>
            <DoctorInfo
              doctor={{
                // @ts-ignore
                patient: {
                  first: "Mindy",
                  last: "Smith",
                  timezone: "America/NewYork",
                  phone: "9543811245"
                }
              }}
            />
          </View>
        )}
      </View>

      {!isKeyboardVisible && (
        <Button
          title={LocalizedStrings.screens.doctorSharing.shareWithDoctor}
          onPress={() =>
            navigation.navigate("ConfirmDoctorSharing", {
              doctors: selectedDoctors
            })
          }
          disabled={selectedDoctors.length === 0}
        />
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 20
    },
    flex1: {
      flex: 1,
      gap: 20
    },
    textDarkGreyBlue: {
      color: theme.colors.darkGreyBlue
    }
  };
});

export default DoctorSharingScreen;
