import { makeStyles } from "@rneui/themed";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Spacing from "../../components/ui/Spacing";
import DatePicker from "../../components/ui/DatePicker";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import { useMemo } from "react";
import Button from "../../components/ui/Button";
import { ScrollView, View } from "react-native";
import { isTruthy } from "common/helpers/helpers";

interface FormType {
  fromDate: DateTime;
  toDate: DateTime;
}

const AddEditOffTimeScreen = ({ navigation, route }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const validate = (values: FormType) => {
    let errors = {};
    if (values.toDate < values.fromDate) {
      errors["toDate"] =
        LocalizedStrings.screens.addEditOffTime.errorToDateMustBeAfterFromDate;
    }
    return errors;
  };

  const { values, errors, setFieldValue, handleSubmit, isValid } =
    useFormik<FormType>({
      validate,
      initialValues: {
        fromDate: now,
        toDate: now.plus({ days: 14 })
      },
      onSubmit: (values) => {},
      enableReinitialize: true
    });

  const errorString = (errors.fromDate ?? errors.toDate) as string;

  const headerText = route.params?.offtime_id
    ? LocalizedStrings.screens.addEditOffTime.headerEdit
    : LocalizedStrings.screens.addEditOffTime.headerAdd;

  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.container}>
          <Text h3 style={[textStyles.colorDarkGreyBlue]}>
            {headerText}
          </Text>
          <Text body style={[textStyles.colorGreyBlue]}>
            {LocalizedStrings.screens.addEditOffTime.content}
          </Text>

          <DatePicker
            title={LocalizedStrings.common.fromDate}
            date={values.fromDate}
            onDateChanged={(date: DateTime) => {
              setFieldValue("fromDate", date);
            }}
            minimumDate={now}
          />
          <DatePicker
            title={LocalizedStrings.common.toDate}
            date={values.toDate}
            onDateChanged={(date: DateTime) => {
              setFieldValue("toDate", date);
            }}
            minimumDate={values.fromDate}
          />
          {isTruthy(errorString) && (
            <Text body style={textStyles.colorError}>
              {errorString}
            </Text>
          )}
          <Text
            body
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.addEditOffTime.affectedAppointments} 15
          </Text>

          <Button
            title={LocalizedStrings.common.save}
            onPress={() => handleSubmit()}
            disabled={!isValid}
          />

          <Button
            type="outline"
            title={LocalizedStrings.common.cancel}
            onPress={() => navigation.goBack()}
          />

          <Spacing vertical={2} />
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20,
      paddingTop: 20,
      gap: 10,
      maxWidth: 500,
      alignSelf: "center"
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    rowSpaceBetween: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }
  };
});

export default AddEditOffTimeScreen;
